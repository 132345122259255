.services .services-content h2 {
  margin-bottom: 4vh;
  font-weight: 700;
  font-size: 42px;
}
.services .services-content {
  height: 75vh;
}

.services .services-image {
  background-image: url(../../../assets/home/services//summary-solution.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 55vh;
  width: 30vw;
  animation: 4s zoom infinite ease-in-out;
}
@keyframes zoom {
  0%,
  100% {
    transform: scale(1); /* Start and end at the same position */
  }
  50% {
    transform: scale(0.9); /* Slight bounce movement */
  }
}
.services .services-solution {
  margin-bottom: 5vh;
}

.services .services-solution-content h3 {
  font-weight: 800;
  font-size: 18px;
  margin: 0;
}

.services .services-solution-image {
  width: 50px;
  height: 40px;
  object-fit: contain;
  margin-bottom: 20px;
  margin-right: 10px;
}

@media (max-width: 992px) {
  .services .services-content {
    height: auto;
  }
  .services .services-image {
    width: 80%;
    height: 50vh;
  }
}
