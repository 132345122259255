.hero .row {
  height: 53vh;
  margin-top: 2vh;
}
.hero .hero-content {
  text-align: left;
  z-index: 2;
}
.hero .hero-content h1 {
  font-weight: 800;
  font-size: 62px;
  /* color: #eab862; */
}

.hero-image {
  width: 40vw;
  height: 50vh;
  object-fit: contain;
  animation: bounce 3s ease-in-out infinite; /* Smoother easing and shorter duration */
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(10px); /* Start and end at the same position */
  }
  50% {
    transform: translateY(-10px); /* Slight bounce movement */
  }
}

@media (max-width: 1192px) {
  .hero-image {
    width: 35vw;
  }
}
@media (max-width: 992px) {
  .hero-image {
    width: 100%;
    position: absolute;
  }
  .hero .hero-content {
    background: radial-gradient(#000b1f, transparent);
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 478px) {
  .hero .hero-content h1 {
    font-weight: 800;
    font-size: 42px;
  }
  .hero .row {
    min-height: 50vh;
    height: auto;
  }
}
