.pricing {
  background-image: url("../../../assets/euro.png");
  background-position: center;
  background-size: 30%;
  background-attachment: fixed;
  position: relative;
}
.pricing::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(#000b1f40, #000b1f);
}
.pricing .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.pricing h2 {
  font-weight: 700;
  font-size: 42px;
  z-index: 1;
  position: relative;
}
.pricing p {
  z-index: 1;
  position: relative;
}
.pricing-header span {
  font-size: 14px;
  font-weight: 400;
  color: #ff6d2e;
  /* margin-left: 3px;
  margin-right: 3px; */
}
.pricing-header h3 {
  font-weight: 600;
  font-size: 16px;
}
.pricing-header h5 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 2vh;
}
.pricing .project-higlight {
  min-width: 330px;
  width: 330px;
  height: 70vh;
  margin: 2em;
  background-position: center;
  background-size: cover;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  /* box-shadow: 0px 0px 10px #f7f7f827; */
  border: solid 1px #f7f7f827;
}
.pricing .project-higlight:nth-child(1) {
  background-color: #635f817a;
  backdrop-filter: blur(5px);
  height: 50vh;

  /* color: #000b1f; */
}
.pricing .project-higlight:nth-child(2) {
  background-color: #292b5d82;
  backdrop-filter: blur(5px);
  height: 60vh;
}

.pricing .project-higlight:nth-child(3) {
  background-color: #29273886;
  backdrop-filter: blur(5px);
  height: 70vh;

  /* color: #000b1f; */
}

.pricing .project-higlight .type {
  background: linear-gradient(to right, #000, #ff6d2e);
  padding: 10px;
  font-size: 14px;
}
.pricing .project-higlight .name {
  background: radial-gradient(#000, #000b1f);
  padding: 10px;
  font-weight: 600;
}
.pricing-breakdown li {
  margin-bottom: 2vh;
}
.pricing-btn {
  background-color: #ff6d2e;
  backdrop-filter: blur(5px);
  text-decoration: none;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  color: white;
  border: none;
}

@media (min-width: 1182px) {
  .pricing .project-higlight {
    width: 22vw;
  }
}
@media (max-width: 992px) {
  .pricing {
    margin-bottom: 5vh;
    margin-top: 10vh;
  }
}
@media (max-width: 448px) {
  .pricing .project-higlight {
    min-width: 90%;
    max-width: 90%;
    width: 90%;
  }
}
