.highlights {
  margin-top: 5vh;
}

.highlights h2 {
  text-align: center;
  font-weight: 700;
  font-size: 42px;
}
.highlights .project-higlights {
  margin-top: 10vh;
}
.highlights .project-higlight {
  width: 260px;
  height: 50vh;
  background-position: center;
  background-size: cover;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 12px;
  border: solid 1px #666a7356;
  box-shadow: 5px 3px 10px #878e9b56;
}
.highlights .project-higlight:nth-child(1) {
  background-image: url(../../../assets/home/highlight/img1.jpeg);
}
.highlights .project-higlight:nth-child(2) {
  background-image: url(../../../assets/home/highlight/img2.jpeg);
}
.highlights .project-higlight:nth-child(3) {
  background-image: url(../../../assets/home/highlight/img3.jpeg);
}

.highlights .project-higlight .type {
  background: linear-gradient(to right, #000, #ff6d2e);
  padding: 10px;
  font-size: 14px;
  border-radius: 12px;
}
.highlights .project-higlight .name {
  background: radial-gradient(#000, #000b1f);
  padding: 10px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 12px;

  color: #fff;
}

@media (min-width: 1192px) {
  .highlights .project-higlight {
    width: 20vw;
  }
}
@media (max-width: 992px) {
  .highlights .project-higlight {
    margin: 2em;
    width: 40%;
  }
}
@media (max-width: 768px) {
  .highlights {
    margin-top: 10vh;
  }
  .highlights .project-higlight {
    margin: 2em;
    width: 80%;
    height: 60vh;
  }
  .highlights .project-higlights {
    margin-top: 2vh;
  }
}
