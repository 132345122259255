.achievements {
  background: linear-gradient(to left, #000b1f, #ff6d2e, #000b1f);
  height: 30vh;
  margin-top: 5vh;
  margin-bottom: 10vh;
  background-size: 400% 100%;
  animation: wave 10s ease-in-out infinite;
  /* clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%); */
  border-radius: 20px;
}

@keyframes wave {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.achievements .achievement {
  width: 180px;
  height: 25vh;
  background-color: #000;
  border-radius: 10px;
  padding: 20px;
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 20px;
  text-align: center;
  box-shadow: 3px 5px 5px #d3d3d32a;
}

.achievements .achievement img {
  color: white;
  width: 30%;
}
.achievements .achievement .content h3 {
  font-weight: 600;
  margin: 0;
}

@media (min-width: 1192px) {
  .achievements .achievement {
    width: 15vw;
  }
}
@media (max-width: 992px) {
  .achievements {
    clip-path: none;
    height: auto;
    padding: 2vh;
  }
  .achievements .achievement {
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: row;
    margin: 10px;
    padding: 18px;
  }
  .achievements .achievement img {
    color: white;
    width: 60px;
  }
}
