.why-us .container {
  height: 88vh;
}
.why-us .container h2 {
  font-weight: 700;
  font-size: 42px;
}
.why-us .why-us-content {
  height: 55vh;
}
.why-us .why-us-image {
  width: 40vw;
  height: 50vh;
  background-image: url(../../../assets/solutions.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  animation: 4s rotate infinite ease-in-out;
}
@keyframes rotate {
  0%,
  100% {
    transform: rotateZ(-10deg); /* Start and end at the same position */
  }
  50% {
    transform: rotateZ(10deg); /* Slight bounce movement */
  }
}
.why-us-reason {
  background: #292738;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
}
.tiny-circle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: radial-gradient(#ff6d2e, #000b1f);
  margin-right: 10px;
}

@media (max-width: 992px) {
  .why-us {
    margin-top: 5vh;
  }
  .why-us .container {
    height: auto;
  }
  .why-us-reason {
    width: 90%;
  }
  .why-us .why-us-content {
    height: auto;
  }
  .why-us .why-us-image {
    width: 70%;
    height: 40vh;
  }
}
