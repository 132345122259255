.contact {
  margin-top: 8vh;
  margin-bottom: 10vh;
  background-image: url("../../../assets/cloud.png");
  background-position: center;
  position: relative;
  background-attachment: fixed;
}
.contact::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(#000b1f00, #000b1f);
}
.contact h2 {
  font-weight: 700;
  font-size: 42px;
  z-index: 1;
}
.contact .container p {
  z-index: 1;
}
.contact .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.contact .container form {
  background-color: #43434340;
  backdrop-filter: blur(5px);
  color: #e3e3e3;
  padding: 1vw 2vw;
  width: 70%;
  min-height: 50vh;
  text-align: left;
  border-radius: 10px;
}
.contact form input {
  width: 100%;
  height: 5vh;
  border-radius: 10px;
  padding: 10px;
  outline: none;
  border: solid 1px rgba(128, 128, 128, 0.43);
  background-color: #5e5e5e0f;
}
.contact form label {
  font-weight: 600;
}
.contact form textarea {
  height: 25vh;
  border-radius: 10px;
  border: solid 1px rgba(128, 128, 128, 0.43);
  padding: 10px;
  outline: none;
  background-color: #5e5e5e0f;
}
.contact form button {
  width: 200px;
  height: 40px;
  border-radius: 10px;
  border: none;
  margin-top: 2vh;
  background-color: #ff6d2e;
  color: white;
}

@media (max-width: 992px) {
  .contact .container form {
    width: 90%;
    height: auto;
    padding-bottom: 10px;
  }
}
