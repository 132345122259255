.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(#000b1f, #ff6d2e);
  z-index: 9999;
  background-size: 400% 100%;
  animation: wave 2s ease-in-out infinite;
}

@keyframes wave {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
