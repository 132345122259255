.footer {
  margin-top: 8vh;
  color: #d3d3d3;
  font-size: 14px;
  min-height: 15vh;
  padding-bottom: 5vh;
  background-color: #010b20;
}
.footer .footer-top {
  border-bottom: solid 1px rgba(128, 128, 128, 0.318);
  margin-bottom: 2vh;
}
.social-icon {
  color: #ff6d2e;
  margin-right: 5px;
}
@media (max-width: 992px) {
  .footer .footer-logo {
    width: 80%;
  }
}
